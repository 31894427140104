<template>
  <div class="storageContractReview">
    <h1>仓储合同审核</h1>
    <el-form class="form" label-width="auto">
      <el-form-item>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="danger" @click="review('unpass')">不通过</el-button>
          <el-button type="success" @click="review('pass')">通过</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="请填写审核备注：">
        <el-input v-model="data.contract.reviewNote" :max="100" />
      </el-form-item>
      <el-form-item label="合同日期">
        <el-text>{{ data.contract.contractDate }}</el-text>
      </el-form-item>
      <el-form-item label="资质证书">
        <el-upload style="width: 100%;" v-model:file-list="data.file.qualification" :action="api.baseURL() + '/backend/file/upload'" :headers="api.tokenHeader()" multiple drag disabled :on-error="() => ElMessage.error('上传失败')" :limit="10"
          :on-preview="(f) => util.previewElfile(f)">
          <el-text>当前禁止上传</el-text>
        </el-upload>
      </el-form-item>
      <el-form-item label="合同草稿">
        <el-upload style="width: 100%;" v-model:file-list="data.file.contractDraft" :action="api.baseURL() + '/backend/file/upload'" :headers="api.tokenHeader()" multiple drag disabled :on-error="() => ElMessage.error('上传失败')" :limit="10"
          :on-preview="(f) => util.previewElfile(f)">
          <el-text>当前禁止上传</el-text>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const show = reactive({
  typeList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "storageContractReview")
const data = reactive({
  contract: pageParam ? JSON.parse(JSON.stringify(pageParam)) : { 'contractType': 'storage' },
  file: {}
})

data.file.qualification = util.json2Elfile(data.contract.qualification)
data.file.contractDraft = util.json2Elfile(data.contract.contractDraft)

api.get('/backend/contract/getTypes', { params: { code: 'supplier' } }).then(res => {
  show.typeList = res.list
})

const review = (reviewState) => {
  const param = { id: data.contract.id }
  param.reviewNote = data.contract.reviewNote
  param.reviewState = reviewState
  api.post('/backend/contract/review', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}
</script>

<style lang="less">
.storageContractReview {
  margin: auto;
  max-width: 900px;
}
</style>